/* eslint-disable react/forbid-dom-props */
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import styles from './App.css';

function App() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(async () => {
    if (isLoading) {
      // console.log('app is loading');
      return;
    }

    if (!isAuthenticated) {
      await loginWithRedirect();
    }
  }, [isLoading, isAuthenticated]);

  return isAuthenticated ? (
    <div height="100%" width="100%">
      <iframe
        height="100%"
        width="100%"
        style={styles.iframe}
        src="https://app.powerbi.com/view?r=eyJrIjoiZmIzZDdiMTEtZDE2Yy00ODVhLWJmMjktYmE4ODM5ODY1NDE4IiwidCI6ImI0ZjdmZTU4LTU1ODQtNDExNi05MzdiLWQxZWJlZDkxYTA0NSJ9&pageName=ReportSection59d93ec6d68fb1bbfa58"
      />
    </div>
  ) : (
    <div />
  );
}

export default App;
