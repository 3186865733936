import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';

ReactDOM.render(
  <Auth0Provider
    domain="deq.us.auth0.com"
    clientId="AWHN2IegaKPjZBs0GRgD6A24Ci3XLVN6"
    redirectUri="https://kenya-hate-monitor.d-eq.org"
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);
